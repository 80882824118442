@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.home-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Allow container to expand */
  overflow: auto; /* Allow scrolling */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: relative; /* Ensure navbar has relative positioning */
}

.navbar-logo img {
  width: 120px;
  height: auto;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-left: auto;
}

.navbar-menu li {
  cursor: pointer;
  position: relative; /* Ensure the dropdown is positioned relative to its parent */
}

.navbar-menu li ul {
  display: none; /* Hide the dropdown by default */
  position: absolute;
  top: 100%; /* Position the dropdown below the parent item */
  left: 0;
  background-color: rgba(255, 255, 255, 0.9); /* Set background color with 80% opacity */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px;
  margin: 0;
  width: 200px; /* Width of the dropdown */
  z-index: 100; /* Higher z-index to make sure it's above other content */
  border-radius: 10px; /* Add rounded corners */
  transition: all 0.3s ease; /* Smooth transition for the dropdown */
}

.navbar-menu li:hover ul {
  display: block; /* Show the dropdown on hover */
}

.navbar-menu li ul li {
  padding: 10px;
  color: #333333; /* Color for the dropdown items */
}

.navbar-menu li ul li:hover {
  background-color: #bbceba; /* Change background on hover */
}

.quote-button {
  background-color: #5f8971; /* SaddleBrown color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 20px; /* Add margin to the left of the button */
}

.quote-button:hover {
  background-color: #7e9d8c; /* Sienna color on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

.slider {
  width: 100%;
  height: 50vh;
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none; /* Ensure no border */
  outline: none; /* Ensure no outline */
  user-select: none; /* Prevent text selection */
  pointer-events: none; /* Disable pointer events */
}

.banner-content {
  position: absolute;
  top: 20%; /* Adjust this value if needed */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.banner-content h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.banner-content p {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.banner-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px; /* Optional: Add some margin-top for better spacing */
}

.enquire-button,
.services-button {
  background-color: #5f8971; /* DarkOliveGreen color */
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.enquire-button:hover,
.services-button:hover {
  background-color: #7e9d8c; /* OliveDrab color on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

img:focus, img:active {
  outline: none;
  border: none;
}

.slick-dots {
  bottom: 10px !important; /* Ensure dots are at the bottom */
  text-align: center; /* Center the dots */
  width: 100%;
}

.slick-dots li {
  display: inline-block;
}

.slick-dots li button:before {
  color: white; /* Default dot color */
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: rgb(0, 0, 0);
}

.slick-dots li button:hover:before {
  color: #cacaca; /* Light white color on hover */
}

.services-section {
  padding: 10px 20px; /* More padding for better spacing */
  text-align: center;
  background-color: #f0f0f0; /* Subtle background color for contrast */
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 700;
  color: #5f8971; /* Darker color for the heading */
}

.services-section p {
  font-size: 1.2em;
  color: #555;
  max-width: 800px;
  margin: 0 auto 30px auto;
  line-height: 1.6; /* Better readability */
}

.services-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for services */
  gap: 40px; /* Reduced gap for closer spacing */
  justify-items: center; /* Center each service item */
  align-items: start; /* Align items to the top */
  margin: 0 auto; /* Center the grid on the page */
  max-width: 800px; /* Restrict maximum width for the grid */
}

.service-item {
  width: 100%; /* Use full width of the grid column */
  max-width: 380px; /* Set a max width for each service item */
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-item img {
  width: 100%;
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Ensure the image scales to cover the area */
  border-radius: 15px;
  margin-bottom: 0px;
}

.service-item p {
  font-size: 1.1em;
  color: #333;
  margin: 5px 0; 
  padding: 0px 1px;
  font-weight: 600;
}
